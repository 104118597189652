.main-container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 2rem;
}

@media (max-width: 1200px) {
    .main-container {
        padding: 2.5rem;
    }
}